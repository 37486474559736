import createTypes from 'redux-create-action-types';

export default createTypes(
  'UPDATE_TOKEN_ADDED_AT',
  'UPDATE_TOKEN_EXPIRE_AT',
  'UPDATE_TOKEN',
  'REFRESH_TOKEN',
  'GET_ME',
  'LOGIN',
  'CREATE_USER',
  'LOGOUT',
  'FORGOT_PASSWORD',
  'VALIDATE_TOKEN',
  'RESET_PASSWORD',
  'CONFIRM_REGISTER',
  'UPDATE_PROFILE',
  'CHANGE_PASSWORD',
  'VALIDATE_INVITE_TOKEN',
  'CREATE_ACCOUNT',
  'VALIDATE_CLIENT_TOKEN',
  'CREATE_CLIENT_ACCOUNT',
  'VALIDATE_PASSWORD',
  'DELETE_ACCOUNT'
);
