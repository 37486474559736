import findIndex from 'lodash/findIndex';
import pick from 'lodash/pick';
import get from 'lodash/get';
import compact from 'lodash/compact';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import { getOtherDataValue } from 'utils/customField';
import {
  standardRequiredFieldObj,
  phoneFieldObj,
  emailFieldObj,
  getFileFieldInitialValues,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate } from 'utils/date';
import {
  CLASS_TOOLTIP_MAPPING,
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  BOOLEAN_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  ARR_FIELDS,
  MAILING_ADDRESS_FIELDS,
  IS_SAME_ADDRESS_FIELD,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const isAlreadyNew = sections => {
  return findIndex(sections, { isNew: true }) >= 0;
};
export const filteredClassOptions = options => {
  return options.map(option => ({
    ...option,
    tooltip: CLASS_TOOLTIP_MAPPING[option.name] || '',
  }));
};
export const isDriverLicenseSection = titleObj => {
  return (
    titleObj &&
    ['Owner/Member', 'Owner/Operator', 'President'].includes(titleObj.name)
  );
};
export const isMedicalCardSection = titleObj => {
  return titleObj && ['Owner/Operator'].includes(titleObj.name);
};
export const validateObj = {
  first_name: [standardRequiredFieldObj],
  last_name: [standardRequiredFieldObj],
  title_id: [standardRequiredFieldObj],
  phone: [standardRequiredFieldObj, phoneFieldObj],
  email: [emailFieldObj],
};
export const getInitialValues = (data, isInternalUser) => {
  const initialValues = {
    delete_attachments: [],
    ownership: parseStringToNumber(data?.ownership),
    delete_custom_fields: [],
    custom_fields: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    if (['ssn', 'dl_number'].includes(field) && isInternalUser) {
      initialValues[field] = '';
    } else {
      initialValues[field] =
        data[field] || (NULL_FIELDS.includes(field) ? null : '');
    }
    return true;
  });
  DATE_FIELDS.map(field => {
    if (field === 'dob' && isInternalUser) {
      initialValues[field] = '';
    } else {
      initialValues[field] = data[field]
        ? getMomentDate(data[field], false, true)
        : '';
    }
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });
  ARR_FIELDS.map(field => {
    initialValues[field] = data[field] ? data[field].map(v => v.id) : [];
    return true;
  });
  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getCompanyOfficialBR = (values = {}, officialId) => {
  const { delete_attachments, custom_fields, delete_custom_fields } = values;
  let isSameAddress = false;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (officialId) {
    bodyRequest.employee_id = officialId;
  }
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    if (field === IS_SAME_ADDRESS_FIELD) {
      isSameAddress = bodyRequest[field];
    }
    return true;
  });
  if (isSameAddress) {
    MAILING_ADDRESS_FIELDS.map(field => {
      bodyRequest[field] = values[field.replace('mailing_', '')];
      return true;
    });
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  if (!values.dob) {
    delete bodyRequest.dob;
  }
  if (!values.ssn) {
    delete bodyRequest.ssn;
  }
  if (!values.dl_number) {
    delete bodyRequest.dl_number;
  }
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  ARR_FIELDS.map(field => {
    const vals = values[field] || [];
    vals.map(val => {
      fData.append(`${field}[]`, val);
      return true;
    });
    if (vals.length === 0) {
      fData.append(`${field}[]`, '');
    }
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_attachments[]', id);
      return true;
    });
  }
  if (custom_fields && custom_fields.length > 0) {
    custom_fields.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
export const getLabel = (data, comma) => {
  const { label, first_name, last_name } = data || {};
  return label
    ? label
    : compact([first_name, last_name]).join(comma ? ', ' : ' ');
};
export const afterCbFn = (cbSuccess, cbError) => (res, err) => {
  if (res) {
    if (cbSuccess) cbSuccess(res);
  } else {
    toast.error(getErrorMessageFromObj(err)[0]);
    if (cbError) cbError();
  }
};
