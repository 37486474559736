import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import * as sharedSelectors from 'store/selectors/shared';
import * as authSelectors from 'store/selectors/auth';
import * as orderTypeSelectors from 'store/selectors/orderType';
import {
  ROLES,
  QUEUE_DEPARTMENTS,
  MY_TEAM_DISPLAY_TABS,
  ORDER_TYPE_HIDDEN_TABS,
  COMPLETED_WORK_QUEUE_NAME,
} from 'helper/const';
import { isAdminFilter } from 'utils/role';

const useIsAdmin = () => {
  const currentUser = authSelectors.useSelectCurrentUser();
  const currentRole = currentUser.role || '';
  return currentRole === ROLES.ADMIN;
};

const useIsSuperAdmin = () => {
  const currentUser = authSelectors.useSelectCurrentUser();
  const currentRole = currentUser.role || '';
  return currentRole === ROLES.SUPER_ADMIN;
};

const useIsInternalUser = () => {
  const currentUser = authSelectors.useSelectCurrentUser();
  const currentRole = currentUser.role || '';
  const isInternalUser =
    currentRole === ROLES.SUPER_ADMIN || !!currentUser.group_id;
  const isExternalOwner = [ROLES.OWNER_OPERATOR, ROLES.OWNER_MEMBER].includes(
    currentRole
  );
  return {
    isInternalUser,
    isExternalOwner,
  };
};
const useIsOwnerRecord = id => {
  const currentUser = authSelectors.useSelectCurrentUser();
  return currentUser.user_employee_id === id;
};

const useMyTeamTabIds = getAll => {
  const tabs = MY_TEAM_DISPLAY_TABS;
  const departments = sharedSelectors.useSelectDepartments();
  const isAdmin = useIsAdmin();
  // '' is All Departments, 0 is Inactive tab
  return [
    '',
    ...tabs
      .filter(t => getAll || isAdminFilter(isAdmin, t))
      .map(t => {
        const foundTab = find(departments, { slug: t }) || {};
        return foundTab.id || '';
      }),
    0,
  ];
};
const useWorkQueuePageIds = () => {
  const departments = sharedSelectors.useSelectDepartments();
  return QUEUE_DEPARTMENTS.map(department => {
    const foundDepartment = find(departments, { slug: department.slug }) || {};
    return {
      ...department,
      id:
        department.slug === COMPLETED_WORK_QUEUE_NAME.NORMAL
          ? COMPLETED_WORK_QUEUE_NAME.NORMAL
          : foundDepartment.id || '',
    };
  });
};

const useOptions = () => {
  const isAdmin = useIsAdmin();
  const fieldCategories = sharedSelectors.useSelectFieldCategories();
  const rawdepartments = sharedSelectors.useSelectDepartments();
  const departments = sharedSelectors.useSelectDepartmentsList().map(d => ({
    ...find(rawdepartments, { name: d.name }),
    name: d.name,
  }));
  const statesList = sharedSelectors.useSelectStates();
  const scTitles = sharedSelectors.useSelectSCTitles();
  const scProspectSources = sharedSelectors.useSelectSCProspectSources();
  const scDotNumberStatus = sharedSelectors.useSelectSCDotNumberStatus();
  const scIndustries = sharedSelectors.useSelectSCIndustries();
  const scBusinessEntities = sharedSelectors.useSelectSCBusinessEntities();
  const scClass = sharedSelectors.useSelectSCClass();
  const scEndorsements = sharedSelectors.useSelectSCEndorsements();
  const scRestrictions = sharedSelectors.useSelectSCRestrictions();
  const scTruckTypes = sharedSelectors.useSelectSCTruckTypes();
  const scTrailerTypes = sharedSelectors.useSelectSCTrailerTypes();
  const scOtherFieldTypes = sharedSelectors.useSelectSCOtherFieldTypes();
  const scGVW = sharedSelectors.useSelectSCGVWTypes();
  const scBankruptcyStatuses = sharedSelectors.useSelectSCBankruptcyStatuses();
  const scLienTypes = sharedSelectors.useSelectSCLienTypes();
  const scEvictionTypes = sharedSelectors.useSelectSCEvictionTypes();
  const scTypeOfViolations = sharedSelectors.useSelectSCTypeOfViolations();
  const scCollateralTypes = sharedSelectors.useSelectSCCollateralTypes();
  const scIndustryTypes = sharedSelectors.useSelectSCIndustryTypes();
  const scPullTypes = sharedSelectors.useSelectSCPullTypes();
  const scReminderTriggerFields = sharedSelectors.useSelectSCReminderTriggerFields();
  const scReminderRecurrence = sharedSelectors.useSelectSCReminderRecurrence();
  const scReminderFrequency = sharedSelectors.useSelectSCReminderFrequency();
  const salesDepartmentUsers = sharedSelectors.useSelectSalesDepartmentUsers();
  const driversList = sharedSelectors.useSelectDriversList();
  const trailersList = sharedSelectors.useSelectTrailersList();
  const employeeList = sharedSelectors.useSelectEmployeeList();
  const clientList = sharedSelectors.useSelectClientList();
  const paginationClientList =
    sharedSelectors.useSelectPClientList()?.data || [];
  const paginationEmployeeList =
    sharedSelectors.useSelectPEmployeeList()?.data || [];
  const paginationOrderTypeList =
    sharedSelectors.useSelectPOrderTypeList()?.data || [];
  const statusList = sharedSelectors.useSelectStatusList();
  const allOrderTypeList = orderTypeSelectors.useSelectAllOrderTypesList();
  const roles = sharedSelectors.useSelectRoles();
  const leasorsList = sharedSelectors.useSelectLeasorsList();
  const departmentOptions = (departments || [])
    .filter(d => isAdminFilter(isAdmin, d.slug))
    .map(d => ({
      value: d.id,
      label: d.name,
    }));
  const orderTypeDepartmentOptions = (departments || [])
    .filter(
      d =>
        isAdminFilter(isAdmin, d.slug) &&
        !ORDER_TYPE_HIDDEN_TABS.includes(d.slug)
    )
    .map(d => ({
      value: d.id,
      label: d.name,
    }));
  const fieldCategoryOptions = (fieldCategories || []).map(cat => ({
    value: cat.id,
    label: cat.name,
  }));
  const stateOptions = (statesList || []).map(s => ({
    value: s.id,
    label: s.name,
  }));
  const scTitleOptions = scTitles.map(title => ({
    value: title.id,
    label: title.label,
    name: title.name,
  }));
  const scTitleWithoutDriverOptions = scTitleOptions.filter(
    option => option.name !== 'Driver'
  );
  const scProspectSourceOptions = scProspectSources.map(prospect => ({
    value: prospect.id,
    label: prospect.label,
  }));
  const scDotNumberStatusOptions = scDotNumberStatus.map(dot => ({
    value: dot.id,
    label: dot.label,
  }));
  const scIndustryOptions = scIndustries.map(industry => ({
    value: industry.id,
    label: industry.label,
  }));
  const scBusinessEntityOptions = scBusinessEntities.map(entity => ({
    value: entity.id,
    label: entity.label,
  }));
  const scClassOptions = scClass.map(obj => ({
    value: obj.id,
    label: obj.label,
    name: obj.name,
  }));
  const scEndorsementOptions = scEndorsements.map(endorsement => ({
    value: endorsement.id,
    label: endorsement.label,
  }));
  const scRestrictionOptions = scRestrictions.map(restriction => ({
    value: restriction.id,
    label: restriction.label,
  }));
  const scTruckTypeOptions = scTruckTypes.map(truck => ({
    value: truck.id,
    label: truck.label,
  }));
  const scTrailerTypeOptions = scTrailerTypes.map(trailer => ({
    value: trailer.id,
    label: trailer.label,
  }));
  const scOtherFieldTypeOptions = scOtherFieldTypes.map(type => ({
    value: type.id,
    label: type.label,
  }));
  const scGVWOptions = scGVW.map(gvw => ({
    value: gvw.id,
    label: gvw.label,
  }));
  const scBankruptcyStatusOptions = scBankruptcyStatuses.map(bs => ({
    value: bs.id,
    label: bs.label,
  }));
  const scLienTypeOptions = scLienTypes.map(lt => ({
    value: lt.id,
    label: lt.label,
  }));
  const scEvictionTypeOptions = scEvictionTypes.map(et => ({
    value: et.id,
    label: et.label,
  }));
  const scTypeOfViolationOptions = scTypeOfViolations.map(tv => ({
    value: tv.id,
    label: tv.label,
  }));
  const scCollateralTypeOptions = scCollateralTypes.map(ct => ({
    value: ct.id,
    label: ct.label,
  }));
  const scIndustryTypeOptions = scIndustryTypes.map(it => ({
    value: it.id,
    label: it.label,
  }));
  const scPullTypeOptions = scPullTypes.map(pt => ({
    value: pt.id,
    label: pt.label,
  }));
  const scReminderTriggerFieldOptions = sortBy(
    scReminderTriggerFields,
    'label'
  ).map(o => ({
    value: o.id,
    label: o.label,
  }));
  const scReminderRecurrenceOptions = scReminderRecurrence.map(o => ({
    value: o.id,
    label: o.label,
  }));
  const scReminderFrequencyOptions = scReminderFrequency.map(o => ({
    value: o.id,
    label: o.label,
  }));
  const salesDepartmentUserOptions = salesDepartmentUsers.map(user => ({
    value: user.id,
    label: user.name,
  }));
  const driverOptions = driversList.map(driver => ({
    value: driver.id,
    label: driver.name,
  }));
  const trailerOptions = trailersList.map(trailer => ({
    value: trailer.id,
    label: trailer.trailer_unit,
  }));
  const employeeOptions = employeeList.map(employee => ({
    value: employee.id,
    label: employee.full_name,
  }));
  const clientOptions = clientList.map(client => ({
    value: client.id,
    label: client.name,
  }));
  const paginationClientOptions = paginationClientList.map(client => ({
    value: client.id,
    label: client.name,
  }));
  const paginationEmployeeOptions = paginationEmployeeList.map(employee => ({
    value: employee.id,
    label: employee.full_name,
  }));
  const paginationOrderTypeptions = paginationOrderTypeList.map(orderType => ({
    value: orderType.value,
    label: orderType.label,
  }));
  const statusOptions = statusList.map(status => ({
    value: status.id,
    label: status.value,
  }));
  const allOrderTypeOptions = allOrderTypeList.map(orderType => ({
    value: orderType.value,
    label: orderType.label,
  }));
  const roleOptions = roles.map(role => ({
    value: role.id,
    label: role.name,
  }));
  const leasorOptions = leasorsList.map(leasor => ({
    value: leasor.id,
    label: leasor.name,
  }));
  return {
    departmentOptions,
    fieldCategoryOptions,
    stateOptions,
    scTitleOptions,
    scTitleWithoutDriverOptions,
    scProspectSourceOptions,
    scDotNumberStatusOptions,
    scIndustryOptions,
    scBusinessEntityOptions,
    scClassOptions,
    scEndorsementOptions,
    scRestrictionOptions,
    scTruckTypeOptions,
    scTrailerTypeOptions,
    scOtherFieldTypeOptions,
    scGVWOptions,
    scBankruptcyStatusOptions,
    scLienTypeOptions,
    scEvictionTypeOptions,
    scTypeOfViolationOptions,
    scCollateralTypeOptions,
    scIndustryTypeOptions,
    scPullTypeOptions,
    scReminderTriggerFieldOptions,
    scReminderRecurrenceOptions,
    scReminderFrequencyOptions,
    salesDepartmentUserOptions,
    driverOptions,
    trailerOptions,
    employeeOptions,
    clientOptions,
    statusOptions,
    allOrderTypeOptions,
    roleOptions,
    leasorOptions,
    orderTypeDepartmentOptions,
    paginationClientOptions,
    paginationEmployeeOptions,
    paginationOrderTypeptions,
  };
};

export {
  useMyTeamTabIds,
  useIsAdmin,
  useOptions,
  useWorkQueuePageIds,
  useIsSuperAdmin,
  useIsInternalUser,
  useIsOwnerRecord,
};
