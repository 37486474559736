import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_CLIENT_DETAIL',
  'GET_CLIENT_DETAIL_LOG',
  'GET_COMPANY_OFFICIALS',
  'GET_COMPANY_OFFICIALS_TOTAL',
  'UPDATE_SALES_INFO',
  'UPDATE_COMPANY',
  'UPDATE_FILLING',
  'UPDATE_INSURANCE',
  'MANAGE_LOGO',
  'ADD_LOG',
  'CREATE_COMPANY_OFFICIAL',
  'UPDATE_COMPANY_OFFICIAL',
  'UPDATE_COMPANY_OFFICIAL_STATUS',
  'SEARCH_SAFER',
  'DOT_SEARCH_SAFER',
  'SYNC_UPDATE_CLIENT_DETAIL_SECTION',
  'SYNC_UPDATE_COMPANY_OFFICIAL',
  'SYNC_SET_SECTION_TOTAL',
  'SYNC_SET_CO_PRIMARY_CONTACT',
  'SAVE_DOT',
  'ARCHIVE_CLIENT',
  'UNARCHIVE_CLIENT',
  'DELETE_CLIENT',
  'GET_DOT'
);
