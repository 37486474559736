import {
  apiCreateAccount,
  apiValidateInviteToken,
  apiGetMe,
  apiLogin,
  apiCreateUser,
  apiForgotPassword,
  apiValidateToken,
  apiResetPassword,
  apiLogout,
  apiConfirmRegister,
  apiUpdateProfile,
  apiChangePassword,
  apiValidateClientToken,
  apiCreateClientAccount,
  apiValidatePassword,
  apiRefreshToken,
  apiDeleteAccount,
} from 'api';
import get from 'lodash/get';

import { authTypes } from '../types';

// CUSTOM
export const updateTokenAddedAt = tokenAddedTime => dispatch => {
  dispatch({
    type: authTypes.UPDATE_TOKEN_ADDED_AT,
    payload: tokenAddedTime,
  });
};

export const updateTokenExpireAt = tokenExpireTime => dispatch => {
  dispatch({
    type: authTypes.UPDATE_TOKEN_EXPIRE_AT,
    payload: tokenExpireTime,
  });
};

export const updateToken = token => dispatch => {
  dispatch({
    type: authTypes.UPDATE_TOKEN,
    payload: token,
  });
};

// GET_ME
export const getMe = cb => {
  return {
    isAsyncCall: true,
    baseType: authTypes.GET_ME,
    asyncCall: () => {
      return apiGetMe();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// LOGIN
export const doLogin = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.LOGIN,
    asyncCall: () => {
      return apiLogin(data);
    },
    afterSuccessCall: (dispatch, response) => {
      const tokenExpireAt =
        get(response, 'data.token_expires_in', 0) * 1000 * 60;
      dispatch(updateTokenAddedAt(new Date().getTime()));
      dispatch(updateTokenExpireAt(tokenExpireAt));
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// LOGOUT
export const doLogout = cb => async dispatch => {
  apiLogout();
  dispatch({
    type: authTypes.LOGOUT,
  });
  if (cb && typeof cb === 'function') cb();
};
// CREATE_USER
export const createUser = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.CREATE_USER,
    asyncCall: () => {
      return apiCreateUser(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// FORGOT_PASSWORD
export const forgotPassword = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.FORGOT_PASSWORD,
    asyncCall: () => {
      return apiForgotPassword(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// VALIDATE_TOKEN
export const validateForgotPasswordToken = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.VALIDATE_TOKEN,
    asyncCall: () => {
      return apiValidateToken(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// RESET_PASSWORD
export const resetPassword = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.RESET_PASSWORD,
    asyncCall: () => {
      return apiResetPassword(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CONFIRM_REGISTER
export const confirmRegister = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.CONFIRM_REGISTER,
    asyncCall: () => {
      return apiConfirmRegister(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// UPDATE_PROFILE
export const updateProfile = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.UPDATE_PROFILE,
    asyncCall: () => {
      return apiUpdateProfile(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CHANGE_PASSWORD
export const changePassword = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.CHANGE_PASSWORD,
    asyncCall: () => {
      return apiChangePassword(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// VALIDATE_INVITE_TOKEN
export const validateInviteToken = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.VALIDATE_INVITE_TOKEN,
    asyncCall: () => {
      return apiValidateInviteToken(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// VALIDATE_CLIENT_TOKEN
export const validateClientToken = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.VALIDATE_CLIENT_TOKEN,
    asyncCall: () => {
      return apiValidateClientToken(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_ACCOUNT
export const createAccount = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.CREATE_ACCOUNT,
    asyncCall: () => {
      return apiCreateAccount(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// CREATE_CLIENT_ACCOUNT
export const createClientAccount = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.CREATE_CLIENT_ACCOUNT,
    asyncCall: () => {
      return apiCreateClientAccount(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
// VALIDATE_PASSWORD
export const validatePassword = (data, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.VALIDATE_PASSWORD,
    asyncCall: () => {
      return apiValidatePassword(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const refreshToken = () => {
  return {
    isAsyncCall: true,
    baseType: authTypes.REFRESH_TOKEN,
    asyncCall: () => {
      return apiRefreshToken();
    },
    afterSuccessCall: (dispatch, response) => {
      const newToken = get(response, 'data.auth_token', '');
      const tokenExpireAt =
        get(response, 'data.token_expires_in', 1440) * 1000 * 60;
      dispatch(updateTokenAddedAt(new Date().getTime()));
      dispatch(updateTokenExpireAt(tokenExpireAt));
      dispatch(updateToken(newToken));
    },
    afterFailureCall: dispatch => {
      dispatch(doLogout());
    },
  };
};
export const deleteAccount = (password, cb) => {
  return {
    isAsyncCall: true,
    baseType: authTypes.DELETE_ACCOUNT,
    asyncCall: () => {
      return apiDeleteAccount(password);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(true);
    },
    afterFailureCall: (dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
