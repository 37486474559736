import React from 'react';
import {
  SolutionOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  BellOutlined,
} from '@ant-design/icons';
import SalesInformation from './components/SalesInformation';
import CompanyInformation from './components/CompanyInformation';
import Forms from './components/Forms';
import Filling from './components/Filling';
import CompanyOfficial from './CompanyOfficial';
import Trailer from './Trailer';
import Truck from './Truck';
import Driver from './Driver';
import Leasor from './Leasor';
import InsuranceInformation from './components/InsuranceInformation';
import ClientRecordTab from './ClientRecordTab';
import OrdersTab from './OrdersTab';
import PaymentSourceTab from './PaymentSourceTab/index';
import DotClassifications from './DotClassifications';
import UnderwritingInfo from './UnderwritingInfo';
import ReminderTab from './ReminderTab';
import { TAB_KEYS } from './constants';

export const getTabs = props => {
  const { permission } = props;
  const displayTabNames = props.tabNames || [];
  const permissionTabs = permission.tabs;
  const returnTabs = [
    {
      title: (
        <span>
          <SolutionOutlined />
          {displayTabNames[0] || 'Client Record'}
        </span>
      ),
      content: <ClientRecordTab {...props} />,
      key: TAB_KEYS.CLIENT_RECORD,
    },
    {
      title: (
        <span>
          <ShoppingCartOutlined />
          {displayTabNames[1] || 'Orders'}
        </span>
      ),
      content: <OrdersTab {...props} />,
      key: TAB_KEYS.ORDERS,
    },
    {
      title: (
        <span>
          <DollarCircleOutlined />
          {displayTabNames[2] || 'Payment Sources'}
        </span>
      ),
      content: <PaymentSourceTab {...props} />,
      key: TAB_KEYS.PAYMENT_SOURCES,
    },
    {
      title: (
        <span>
          <BellOutlined />
          {displayTabNames[3] || 'Alerts'}
        </span>
      ),
      content: <ReminderTab {...props} currentTab={4} />,
      key: TAB_KEYS.REMINDERS,
    },
  ];
  return returnTabs.filter(
    tab =>
      permissionTabs &&
      (permissionTabs.length === 0 || permissionTabs.includes(tab.key))
  );
};
const getCommonProps = props => {
  return {
    confirmModal: props.confirmModal,
    openWarningModal: props.openWarningModal,
    setIsEditing: props.setIsEditing,
    activeTab: props.activeTab,
    clientId: props.clientId,
    setIsEdit: props.setIsEdit,
    status: props.status,
    setStatus: props.setStatus,
    onUpdated: props.onUpdated,
    customField: props.customField,
    permission: props.permission,
  };
};
export const elementMapping = {
  sales_info: props => <SalesInformation {...props} />,
  company_info: props => <CompanyInformation {...props} />,
  forms: props => <Forms {...props} />,
  filings: props => <Filling {...props} />,
  insurance: props => <InsuranceInformation {...props} />,
  company_officials: props => (
    <CompanyOfficial
      {...getCommonProps(props)}
      officials_count={props.officials_count}
    />
  ),
  trailer_info: props => (
    <Trailer {...getCommonProps(props)} trailers_count={props.trailers_count} />
  ),
  truck_info: props => (
    <Truck {...getCommonProps(props)} trucks_count={props.trucks_count} />
  ),
  company_driver_info: props => <Driver {...getCommonProps(props)} />,
  leasor_info: props => <Leasor {...getCommonProps(props)} />,
  dot_classifications: props => <DotClassifications {...props} />,
  underwriting_info: props => <UnderwritingInfo {...props} />,
};
export const settingMapping = {
  sales_info: {},
  company_info: {},
  company_officials: { hideEdit: true },
  trailer_info: { hideEdit: true },
  truck_info: { hideEdit: true },
  company_driver_info: { hideEdit: true },
  leasor_info: { hideEdit: true },
  filings: { hideEdit: true },
  underwriting_info: { hideEdit: true },
  dot_classifications: { hideEdit: true },
};
