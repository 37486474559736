import {
  asyncActionTypeFailure,
  asyncActionTypeRequest,
  asyncActionTypeSuccess,
  authTypes,
} from '../types';
import get from 'lodash/get';
import { initialAuthState } from '../states/auth';

export default (state = initialAuthState, action) => {
  switch (action.type) {
    // CUSTOM
    case authTypes.UPDATE_TOKEN_ADDED_AT: {
      return {
        ...state,
        additionalAttribute: {
          ...state.additionalAttribute,
          tokenAddedAt: action.payload,
        },
      };
    }
    case authTypes.UPDATE_TOKEN_EXPIRE_AT: {
      return {
        ...state,
        additionalAttribute: {
          ...state.additionalAttribute,
          tokenExpireAt: action.payload,
        },
      };
    }
    case authTypes.UPDATE_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          access_token: action.payload,
        },
      };
    }
    // GET_ME
    case asyncActionTypeRequest(authTypes.GET_ME): {
      return {
        ...state,
        currentUser: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.GET_ME): {
      const data = get(action, 'response.data') || {};
      return {
        ...state,
        currentUser: {
          data,
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.GET_ME): {
      return {
        ...state,
        currentUser: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }

    // POST
    // LOGIN
    case asyncActionTypeRequest(authTypes.LOGIN): {
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.LOGIN): {
      const loginData = get(action, 'response.data') || {};
      return {
        ...state,
        login: {
          loading: false,
          access_token: loginData.auth_token || null,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.LOGIN): {
      return {
        ...state,
        login: {
          ...state.login,
          access_token: '',
          loading: false,
          error: action.payload,
        },
      };
    }
    // CREATE_USER
    case asyncActionTypeRequest(authTypes.CREATE_USER): {
      return {
        ...state,
        createUser: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.CREATE_USER): {
      return {
        ...state,
        createUser: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.CREATE_USER): {
      return {
        ...state,
        createUser: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // FORGOT_PASSWORD
    case asyncActionTypeRequest(authTypes.FORGOT_PASSWORD): {
      return {
        ...state,
        forgotPassword: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.FORGOT_PASSWORD): {
      return {
        ...state,
        forgotPassword: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.FORGOT_PASSWORD): {
      return {
        ...state,
        forgotPassword: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // VALIDATE_TOKEN
    case asyncActionTypeRequest(authTypes.VALIDATE_TOKEN): {
      return {
        ...state,
        validateToken: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.VALIDATE_TOKEN): {
      return {
        ...state,
        validateToken: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.VALIDATE_TOKEN): {
      return {
        ...state,
        validateToken: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // RESET_PASSWORD
    case asyncActionTypeRequest(authTypes.RESET_PASSWORD): {
      return {
        ...state,
        resetPassword: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.RESET_PASSWORD): {
      return {
        ...state,
        resetPassword: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.RESET_PASSWORD): {
      return {
        ...state,
        resetPassword: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CONFIRM_REGISTER
    case asyncActionTypeRequest(authTypes.CONFIRM_REGISTER): {
      return {
        ...state,
        confirmRegister: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.CONFIRM_REGISTER): {
      return {
        ...state,
        confirmRegister: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.CONFIRM_REGISTER): {
      return {
        ...state,
        confirmRegister: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // UPDATE_PROFILE
    case asyncActionTypeRequest(authTypes.UPDATE_PROFILE): {
      return {
        ...state,
        updateProfile: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.UPDATE_PROFILE): {
      const updatedData = get(action, 'response.data') || {};
      return {
        ...state,
        updateProfile: {
          data: updatedData,
          loading: false,
          error: '',
        },
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            ...updatedData,
          },
        },
      };
    }
    case asyncActionTypeFailure(authTypes.UPDATE_PROFILE): {
      return {
        ...state,
        updateProfile: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CHANGE_PASSWORD
    case asyncActionTypeRequest(authTypes.CHANGE_PASSWORD): {
      return {
        ...state,
        changePassword: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.CHANGE_PASSWORD): {
      return {
        ...state,
        changePassword: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.CHANGE_PASSWORD): {
      return {
        ...state,
        changePassword: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // VALIDATE_INVITE_TOKEN
    case asyncActionTypeRequest(authTypes.VALIDATE_INVITE_TOKEN): {
      return {
        ...state,
        validateInviteToken: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.VALIDATE_INVITE_TOKEN): {
      return {
        ...state,
        validateInviteToken: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.VALIDATE_INVITE_TOKEN): {
      return {
        ...state,
        validateInviteToken: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // VALIDATE_CLIENT_TOKEN
    case asyncActionTypeRequest(authTypes.VALIDATE_CLIENT_TOKEN): {
      return {
        ...state,
        validateClientToken: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.VALIDATE_CLIENT_TOKEN): {
      return {
        ...state,
        validateClientToken: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.VALIDATE_CLIENT_TOKEN): {
      return {
        ...state,
        validateClientToken: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CREATE_ACCOUNT
    case asyncActionTypeRequest(authTypes.CREATE_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.CREATE_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.CREATE_ACCOUNT): {
      return {
        ...state,
        createAccount: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // CREATE_CLIENT_ACCOUNT
    case asyncActionTypeRequest(authTypes.CREATE_CLIENT_ACCOUNT): {
      return {
        ...state,
        createClientAccount: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.CREATE_CLIENT_ACCOUNT): {
      return {
        ...state,
        createClientAccount: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.CREATE_CLIENT_ACCOUNT): {
      return {
        ...state,
        createClientAccount: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // VALIDATE_PASSWORD
    case asyncActionTypeRequest(authTypes.VALIDATE_PASSWORD): {
      return {
        ...state,
        validatePassword: {
          data: {},
          error: '',
          loading: true,
        },
      };
    }
    case asyncActionTypeSuccess(authTypes.VALIDATE_PASSWORD): {
      return {
        ...state,
        validatePassword: {
          data: get(action, 'response.data'),
          loading: false,
          error: '',
        },
      };
    }
    case asyncActionTypeFailure(authTypes.VALIDATE_PASSWORD): {
      return {
        ...state,
        validatePassword: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};
