import React, { useState } from 'react';
import get from 'lodash/get';
import Tabs from 'components/Tabs';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import { useIsAdmin } from 'helper/hooks';
import toast from 'helper/toast';
import { getErrorMessageFromObj } from 'utils/object';
import AllDepartmentsTab from './AllDepartmentsTab';
import InActiveTab from './InActiveTab';
import Header from './Header';
import Content from './Content';
import MainButton from './MainButton';
import LoadingSpin from './LoadingSpin';
import InviteTeammateModal from './components/InviteTeammateModal';
import {
  onInactiveTeammate,
  onResendInvite,
  onActiveTeammate,
  getInviteTeammateBodyRequest,
  getDoActionBR,
  getFilters,
  getTheRestTabs,
} from './utils';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import classes from './MyTeam.module.scss';

const MyTeam = () => {
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const {
    getAllTeams,
    doActionTeammate,
    inviteTeammate,
    getInActiveTeam,
    getDepartmentTeam,
    resendInviteTeammate,
    getCompanyTeamList,
    inviteCompanyTeamMember,
    updateCompanyTeamMemberStatus,
  } = useActions();
  const {
    departments,
    inviteTeammateLoading,
    inviteCompanyTeamMemberLoading,
    currentUser,
  } = useIndexData();
  const currentRole = currentUser.role || '';
  const companyId = currentUser.company_id || '';
  const permission = getPermission(currentRole, currentUser);
  const isAdmin = useIsAdmin();
  const [globalKeyword, setGlobalKeyword] = useState('');
  const [resetADList, setResetADList] = useState(null);
  const [resetDList, setResetDList] = useState(null);
  const [inviteModal, setInviteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const handleGetAllTeams = (page, sort, keyword, otherFilter = {}) => {
    getAllTeams(getFilters(page, sort, keyword, otherFilter));
  };
  const handleGetDepartmentTeam = (page, sort, keyword, otherFilter = {}) => {
    getDepartmentTeam(getFilters(page, sort, keyword, otherFilter));
  };
  const handleGetCompanyTeamList = (page, sort, keyword, otherFilter = {}) => {
    getCompanyTeamList(companyId, getFilters(page, sort, keyword, otherFilter));
  };
  const handleActionCb = (res, err, mess, cb) => {
    if (res) {
      toast.success(mess);
      if (cb) cb();
    } else {
      toast.error(getErrorMessageFromObj(err)[0]);
    }
  };
  const handleGetInactiveTeam = (page, sort, keyword) => {
    getInActiveTeam(getFilters(page, sort, keyword));
  };
  const handleInactiveTeammate = (records, cb) => {
    onInactiveTeammate(() => {
      if (permission.isDetailCompanyTeam) {
        updateCompanyTeamMemberStatus(
          companyId,
          records.filter(r => r.id !== currentUser.id).map(r => r.id),
          'inactive',
          (res, err) => {
            handleActionCb(
              res,
              err,
              'Teammate(s) deactivated successfully.',
              cb
            );
          }
        );
      } else {
        doActionTeammate(
          getDoActionBR(
            records.filter(r => r.id !== currentUser.id),
            'inactive'
          ),
          (res, err) => {
            handleActionCb(
              res,
              err,
              'Teammate(s) deactivated successfully.',
              cb
            );
          }
        );
      }
    }, confirmModal);
  };
  const handleResendInvite = (records, cb) => {
    onResendInvite(() => {
      resendInviteTeammate(
        {
          id: get(records, '[0].id'),
        },
        res => {
          if (res) {
            toast.success('Teammate(s) resent invite successfully.');
          } else {
            toast.success('Teammate(s) resend invite failed!');
          }
        }
      );
    });
  };
  const handleActiveTeammate = (records, cb) => {
    onActiveTeammate(() => {
      if (permission.isDetailCompanyTeam) {
        updateCompanyTeamMemberStatus(
          companyId,
          records.map(r => r.id),
          'active',
          (res, err) => {
            handleActionCb(res, err, 'Teammate(s) activated successfully.', cb);
          }
        );
      } else {
        doActionTeammate(getDoActionBR(records, 'active'), (res, err) => {
          handleActionCb(res, err, 'Teammate(s) activated successfully.', cb);
        });
      }
    }, confirmModal);
  };
  const tabs = [
    ...(permission.showAdditionalTab
      ? [
          {
            title: <span>All Departments</span>,
            content: (
              <AllDepartmentsTab
                handleGetAllTeams={handleGetAllTeams}
                handleInactive={handleInactiveTeammate}
                handleResendInvite={handleResendInvite}
                handleActive={handleActiveTeammate}
                activeTab={activeTab}
                resetList={resetADList}
                setResetList={setResetADList}
                keyword={globalKeyword}
                setKeyword={setGlobalKeyword}
                permission={permission}
              />
            ),
          },
        ]
      : []),
    ...getTheRestTabs({
      handleGetDepartmentTeam,
      handleInactiveTeammate,
      handleResendInvite,
      handleActiveTeammate,
      activeTab,
      resetList: resetDList,
      setResetList: setResetDList,
      keyword: globalKeyword,
      setKeyword: setGlobalKeyword,
      isAdmin,
      permission,
      handleGetCompanyTeamList,
    }),
    ...(permission.showAdditionalTab
      ? [
          {
            title: <span>Inactive</span>,
            content: (
              <InActiveTab
                handleGetInactiveTeam={handleGetInactiveTeam}
                handleActive={handleActiveTeammate}
                activeTab={activeTab}
                keyword={globalKeyword}
                setKeyword={setGlobalKeyword}
                permission={permission}
              />
            ),
          },
        ]
      : []),
  ];
  return (
    <LoadingSpin>
      <div className={classes.wrapper}>
        <Header />
        <Content>
          <Tabs
            tabs={tabs}
            activeKey={activeTab.toString()}
            onChange={activeKey => {
              const iActiveKey = parseInt(activeKey, 10);
              setActiveTab(iActiveKey);
            }}
            spaceTop
          />
          {!!permission.teamInvite && (
            <MainButton setInviteModal={setInviteModal} />
          )}
        </Content>
      </div>
      <InviteTeammateModal
        isOpen={inviteModal}
        handleClose={() => {
          setInviteModal(false);
        }}
        onSubmit={values => {
          const br = getInviteTeammateBodyRequest(values);
          const cbAction = (res, err) => {
            if (res) {
              setGlobalKeyword('');
              toast.success('Invite sent successfully.');
              setInviteModal(false);
              if (activeTab === 1 && !permission.inviteTeamAsClient) {
                setResetADList(!resetADList);
              } else {
                setResetDList(!resetDList);
              }
            } else {
              const errorMessage = getErrorMessageFromObj(err)[0];
              toast.error(errorMessage);
            }
          };
          if (permission.inviteTeamAsClient) {
            inviteCompanyTeamMember(companyId, br, cbAction);
          } else {
            inviteTeammate(br, cbAction);
          }
        }}
        departments={departments}
        loading={
          permission.inviteTeamAsClient
            ? inviteCompanyTeamMemberLoading
            : inviteTeammateLoading
        }
        activeIndexTab={activeTab - 1}
        permission={permission}
      />
      {contextHolder}
    </LoadingSpin>
  );
};

export default MyTeam;
