import React, { useState, useEffect } from 'react';
import ClientDetailHeader from 'views/pages/ClientDetail/Header';
import ClientDetailMainContent from 'views/pages/ClientDetail/MainContent';
import { useModal } from 'components/Modal';
import { confirmModalBlueConfig, getConfirmModal } from 'utils/modal';
import LoadingSpin from './LoadingSpin';
import { useIndexData, useActions } from './selectorData';
import classes from './MyCompany.module.scss';

const MyCompany = ({ location }) => {
  const [modal, contextHolder] = useModal();
  const confirmModal = getConfirmModal(modal, confirmModalBlueConfig);
  const { currentUser, detailData } = useIndexData();
  const {
    getClientDetail,
    getCompanyOfficials,
    getTrucks,
    getTrailers,
    getFormsMSAs,
    getDrivers,
    getLeasors,
    getBackgrounds,
    getTrailersList,
    getDriversList,
    getAccounts,
    getFuelCards,
    getAllOrderTypesList,
    getOrderRequesterList,
    getOrderList,
    getLeasorsList,
    getActiveCompanyReminders,
    getDismissedCompanyReminders,
    getCompletedCompanyReminders,
    getDOTClassifications,
  } = useActions();
  const clientId = currentUser.company_id;
  const clientName = [
    currentUser.first_name || '',
    currentUser.mi || '',
    currentUser.last_name || '',
  ].join(' ');
  const [avatarFinalSrc, setAvatarFinalSrc] = useState('');
  const [finalFile, setFinalFile] = useState({});
  const { company_logo } = detailData;
  useEffect(() => {
    setAvatarFinalSrc(company_logo || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_logo]);
  useEffect(() => {
    setFinalFile({});
    if (clientId) {
      getClientDetail({ id: clientId });
      getCompanyOfficials({ id: clientId }, { status: 'active' });
      getTrucks({ id: clientId }, { status: 'active' });
      getTrailers({ id: clientId }, { status: 'active' });
      getFormsMSAs({ id: clientId }, { status: 'active' });
      getDrivers({ id: clientId }, { status: 'active' });
      getLeasors({ id: clientId }, { status: 'active' });
      getBackgrounds({ id: clientId });
      getTrailersList(clientId);
      getDriversList(clientId);
      getAccounts(clientId);
      getFuelCards(clientId);
      getAllOrderTypesList();
      getOrderRequesterList(clientId);
      getOrderList(clientId);
      getLeasorsList(clientId);
      getActiveCompanyReminders(clientId);
      getDismissedCompanyReminders(clientId);
      getCompletedCompanyReminders(clientId);
      getDOTClassifications(clientId);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingSpin>
      <div className={classes.wrapper}>
        <div>
          <ClientDetailHeader
            avatarFinalSrc={avatarFinalSrc}
            setAvatarFinalSrc={setAvatarFinalSrc}
            finalFile={finalFile}
            setFinalFile={setFinalFile}
            clientId={clientId}
            breadcrumbs={[
              {
                label: 'My Company',
              },
            ]}
            clientMode
            clientName={clientName}
            confirmModal={confirmModal}
            className={classes.header}
          />
        </div>
        <div>
          <ClientDetailMainContent
            clientId={clientId}
            confirmModal={confirmModal}
            tabNames={['Company Information']}
            location={location}
          />
        </div>
      </div>
      {contextHolder}
    </LoadingSpin>
  );
};

export default MyCompany;
