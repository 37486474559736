const initialClientDetailState = {
  // GET
  clientDetail: {
    loading: false,
    data: {},
    error: '',
  },
  clientDetailLog: {
    loading: false,
    data: [],
    error: '',
  },
  companyOfficials: {
    loading: false,
    data: [],
    error: '',
  },

  // POST
  updateSalesInfo: {
    loading: false,
    data: {},
    error: '',
  },
  updateCompany: {
    loading: false,
    data: {},
    error: '',
  },
  updateFilling: {
    loading: false,
    data: {},
    error: '',
  },
  updateInsurance: {
    loading: false,
    data: {},
    error: '',
  },
  manageLogo: {
    loading: false,
    data: {},
    error: '',
  },
  addLog: {
    loading: false,
    data: {},
    error: '',
  },
  createCompanyOfficial: {
    loading: false,
    data: {},
    error: '',
  },
  updateCompanyOfficial: {
    loading: {},
    data: {},
    error: {},
  },
  updateCompanyOfficialStatus: {
    loading: {},
    data: {},
    error: {},
  },
  searchSafer: {
    loading: false,
    data: {},
    error: '',
  },
  dotSearchSafer: {
    loading: false,
    data: {},
    error: '',
  },
  saveDOT: {
    loading: false,
    data: {},
    error: '',
  },
  DOTClassifications: {
    loading: false,
    data: {},
    error: '',
  },
};

export { initialClientDetailState };
