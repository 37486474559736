import { getSalesInfoBR } from './components/SalesInformation/utils';
import { getCompanyInfoBR } from './components/CompanyInformation/utils';
import { getInsuranceBR } from './components/InsuranceInformation/utils';
import { getFormsBR } from './components/Forms/utils';
import { getFillingBR } from './components/Filling/utils';
import { getUnderwritingBR } from './UnderwritingInfo/utils';

// only display Sales Information and Company Information sections for now
export const DISPLAY_SECTIONS = [
  'sales_info',
  'company_info',
  'company_officials',
  'dot_classifications',
  'trailer_info',
  'truck_info',
  'filings',
  'forms',
  'insurance',
  'company_driver_info',
  'leasor_info',
  'underwriting_info',
];
export const LOADING_MAPPING = {
  sales_info: 'updateSaleInfoLoading',
  company_info: 'updateCompanyLoading',
  filings: 'updateFillingLoading',
  forms: 'updateFormsMSALoading',
  insurance: 'updateInsuranceLoading',
  underwriting_info: 'updateUnderwritingLoading',
};
export const SUBMIT_ACTION_MAPPING = {
  sales_info: 'updateSalesInfo',
  company_info: 'updateCompany',
  filings: 'updateFilling',
  forms: 'updateFormsMSA',
  insurance: 'updateInsurance',
  underwriting_info: 'updateUnderwriting',
};
export const GET_BR_MAPPING = {
  sales_info: getSalesInfoBR,
  company_info: getCompanyInfoBR,
  filings: getFillingBR,
  forms: getFormsBR,
  insurance: getInsuranceBR,
  underwriting_info: getUnderwritingBR,
};
export const SECTION_COUNT_MAPPING = {
  company_officials: 'officials_count',
  trailer_info: 'trailers_count',
  truck_info: 'trucks_count',
  company_driver_info: 'drivers_count',
  leasor_info: 'leasor_count',
};
export const SECTION_ITEM_LABEL_MAPPING = {
  company_officials: 'Official',
  trailer_info: 'Trailer',
  truck_info: 'Truck',
  company_driver_info: 'Driver',
  leasor_info: 'Leasor',
};
export const CUSTOM_EMPTY_SECTION_OBJ = {
  forms: {
    msas: [],
    custom_fields: [],
  },
  filings: {
    general_filing: {},
    ifta_filing: {},
    irp_filing: {},
    kentucky_filing: {},
    new_mexico_filing: {},
    new_york_filing: {},
    oregon_filing: {},
  },
  underwriting_info: {
    background_checks: {},
    business_associations: {},
    buyout: {},
    credit_reports: {},
    documents: {},
    ifa_alerts: {},
    locations: {},
    ucc_filings: {},
  },
};
export const TAB_KEYS = {
  CLIENT_RECORD: 'clientRecordTab',
  ORDERS: 'ordersTab',
  PAYMENT_SOURCES: 'paymentSourceTab',
  REMINDERS: 'reminderTab',
};
export const TAB_KEYS_MAPPING = {
  reminders: 'reminderTab',
};
