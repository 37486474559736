import pick from 'lodash/pick';
import get from 'lodash/get';
import { FORMATS } from 'helper/const';
import { getStrWithFormat } from 'utils/textFormat';
import {
  standardRequiredFieldObj,
  getFileFieldInitialValues,
  phoneFieldObj,
} from 'utils/form';
import { parseStringToNumber } from 'utils/number';
import { getMomentDate, getBEDate } from 'utils/date';
import { getOtherDataValue } from 'utils/customField';
import {
  NORMAL_FIELDS,
  NULL_FIELDS,
  DATE_FIELDS,
  FILE_FIELDS,
  NUMBER_FIELDS,
  BODY_REQUEST_NORMAL_FIELDS,
  BOOLEAN_FIELDS,
  ARR_FIELDS,
  YES_NO_FIELDS,
  IS_SAME_ADDRESS_FIELD,
  MAILING_ADDRESS_FIELDS,
  CONTACT_FIELDS,
} from './constants';

const getFileField = field => {
  if (!field) return '';
  return field.replace('documents.', '');
};
export const validateObj = () => {
  return {
    legal_name: [standardRequiredFieldObj],
    first_name: [standardRequiredFieldObj],
    last_name: [standardRequiredFieldObj],
    title_id: [standardRequiredFieldObj],
    phone: [standardRequiredFieldObj, phoneFieldObj],
  };
};
export const getInitAccountCard = (accounts, cards) => {
  const newAccounts = (accounts || []).map(account => ({
    name_on_account: account.name_on_account,
    account_number: account.account_number,
    is_default_account: !!account.is_primary,
    simple_account_type: 'ACH',
    originId: account.id,
  }));
  const newCards = (cards || []).map(card => ({
    simple_card_number: card.card_number,
    is_primary: !!card.is_primary,
    originId: card.id,
  }));
  return {
    accounts: newAccounts,
    cards: newCards,
  };
};
export const getInitialValues = (data, isInternalUser) => {
  const { accounts, cards } = data || {};
  const initialValues = {
    delete_attachments: [],
    accounts: accounts.map(acc => ({
      ...acc,
      originId: acc.id,
    })),
    cards: cards.map(card => ({
      ...card,
      originId: card.id,
    })),
    delete_custom_fields: [],
    custom_fields: getOtherDataValue(data.custom_fields || []),
  };
  [...NORMAL_FIELDS, ...NULL_FIELDS].map(field => {
    const eValue = data[field];
    if (field === 'ein' && isInternalUser) {
      initialValues[field] = '';
    } else {
      initialValues[field] =
        eValue || (NULL_FIELDS.includes(field) ? null : '');
      if (YES_NO_FIELDS.includes(field) && (eValue || eValue === 0)) {
        initialValues[field] = String(eValue);
      }
    }
    return true;
  });
  CONTACT_FIELDS.map(field => {
    const contactData = data?.contact || {};
    const eValue = contactData[field];
    initialValues[field] = eValue || (NULL_FIELDS.includes(field) ? null : '');
    return true;
  });
  DATE_FIELDS.map(field => {
    initialValues[field] = data[field]
      ? getMomentDate(data[field], false, true)
      : '';
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    initialValues[field] = data[field] ? [field] : [];
    return true;
  });
  ARR_FIELDS.map(field => {
    initialValues[field] = data[field] ? data[field].map(v => v.id) : [];
    return true;
  });

  return {
    ...initialValues,
    ...getFileFieldInitialValues(FILE_FIELDS, data, 'documents'),
  };
};
export const getLeasorBR = (values = {}, leasorId, avatarFile) => {
  const { delete_attachments, custom_fields, delete_custom_fields } = values;
  let isSameAddress = false;
  const bodyRequest = {
    ...pick(values, BODY_REQUEST_NORMAL_FIELDS),
  };
  if (!values.ein) {
    delete bodyRequest.ein;
  }
  if (leasorId) {
    bodyRequest.leasor_id = leasorId;
  }
  DATE_FIELDS.map(field => {
    bodyRequest[field] = values[field] ? getBEDate(values[field]) : '';
    return true;
  });
  YES_NO_FIELDS.map(field => {
    const value = values[field];
    bodyRequest[field] = value ? parseInt(value, 10) : '';
    return true;
  });
  NUMBER_FIELDS.map(field => {
    bodyRequest[field] = parseStringToNumber(values[field]);
    return true;
  });
  BOOLEAN_FIELDS.map(field => {
    bodyRequest[field] = values[field] && values[field].length > 0 ? 1 : 0;
    if (field === IS_SAME_ADDRESS_FIELD) {
      isSameAddress = bodyRequest[field];
    }
    return true;
  });
  if (isSameAddress) {
    MAILING_ADDRESS_FIELDS.map(field => {
      bodyRequest[field] = values[field.replace('mailing_', '')];
      return true;
    });
  }
  const fData = new FormData();
  Object.keys(bodyRequest).map(k => {
    fData.append(
      k,
      bodyRequest[k] || bodyRequest[k] === 0 ? bodyRequest[k] : ''
    );
    return true;
  });
  if (avatarFile && avatarFile.type) {
    fData.append('profile_pic', avatarFile);
  }
  ARR_FIELDS.map(field => {
    const vals = values[field] || [];
    vals.map(val => {
      fData.append(`${field}[]`, val);
      return true;
    });
    if (vals.length === 0) {
      fData.append(`${field}[]`, '');
    }
    return true;
  });
  CONTACT_FIELDS.map(field => {
    const val = values[field] || '';
    fData.append(
      `contact[${field}]`,
      NUMBER_FIELDS.includes(field) ? parseStringToNumber(val) : val
    );
    return true;
  });
  FILE_FIELDS.map(originKey => {
    const key = getFileField(originKey);
    if (values[key] && values[key].length > 0) {
      values[key].map(file => {
        if (file.originFileObj && !file.id) {
          fData.append(`${key}[]`, file.originFileObj);
        }
        return true;
      });
    } else {
      fData.append(key, '');
    }
    return true;
  });
  if (delete_attachments && delete_attachments.length > 0) {
    delete_attachments.map(id => {
      fData.append('delete_documents[]', id);
      return true;
    });
  }
  if (custom_fields && custom_fields.length > 0) {
    custom_fields.map((field, index) => {
      fData.append(`custom_fields[${index}][field_name]`, field.field_name);
      if (field.originId) {
        fData.append(`custom_fields[${index}][id]`, field.originId);
      }
      const firstDocument = get(field, 'document[0]') || null;
      const existingFileName = get(field, 'originData.document_name', '');
      if (firstDocument && !firstDocument.url) {
        fData.append(
          `custom_fields[${index}][document]`,
          firstDocument.originFileObj
        );
      } else if (!firstDocument && !!existingFileName) {
        fData.append(`custom_fields[${index}][document_delete]`, 1);
      }
      return true;
    });
  } else {
    fData.append('custom_fields', '');
  }
  if (delete_custom_fields && delete_custom_fields.length > 0) {
    delete_custom_fields.map(id => {
      fData.append('delete_custom_fields[]', id);
      return true;
    });
  }
  return fData;
};
export const getLeasorContact = data => {
  const { contact, dot_number } = data || {};
  return {
    nameDisplay: `${contact?.first_name}, ${contact?.last_name}`,
    phoneDisplay: getStrWithFormat(contact?.phone, FORMATS.TABLE_PHONE),
    dotDisplay: dot_number || '',
  };
};
