import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { DatePicker as AntdDatePicker } from 'antd';
import { getMomentDate } from 'utils/date';
import { reformatDate } from './utils';
import classes from './DatePicker.module.scss';

const DatePicker = ({
  className,
  onChange,
  form,
  customValidateName,
  ...other
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const input =
      ref && ref.current
        ? // eslint-disable-next-line react/no-find-dom-node
          ReactDOM.findDOMNode(ref.current)?.children[0]?.children[0]
        : null;
    if (input && !other.picker) {
      input.addEventListener(
        'keypress',
        reformatDate(form, customValidateName, other.name)
      );
      input.maxLength = 10;
    }

    return () => {
      if (input && !other.picker) {
        input.removeEventListener('keypress', reformatDate);
      }
    };
  }, [customValidateName, form, other.name, other.picker]);

  return (
    <AntdDatePicker
      {...other}
      onChange={(date, dateString) => {
        if (onChange) onChange(date, dateString);
        if (form && dateString) {
          form.setFieldsValue({
            [customValidateName || other.name]: getMomentDate(dateString),
          });
        }
        if (date && form) {
          form.setFields([
            {
              name: customValidateName || other.name,
              errors: null,
            },
          ]);
        }
      }}
      className={cn(classes.wrapper, className)}
      ref={ref}
    />
  );
};

export default DatePicker;
