import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Spin from 'components/Spin';
import { getMe } from 'store/actions/auth';
import {
  getFieldCategories,
  getDepartments,
  getDepartmentsList,
  getStates,
  getSystemCodes,
  getSalesDepartmentUsers,
  getRoles,
  getClientList,
} from 'store/actions/shared';
import { getReportFields } from 'store/actions/report';
import {
  getUnreadNotifications,
  getReadNotifications,
  getNotificationUnreadCount,
} from 'store/actions/notification';
import socket from 'helper/socket';
import { useRefreshToken } from 'helper/authHooks';
import { QUEUE_DEPARTMENTS } from 'helper/const';
import useMedia from 'helper/useMedia';
import IndexView from './pages/DevComponents';
import ROUTES from '../routes';
import AuthLayout from '../layouts/Auth';
import PublicLayout from '../layouts/Public';
import MainLayout from '../layouts/Main';
import Login from './pages/Auth/Login';
import Registration from './pages/Auth/Registration';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import ConfirmEmail from './pages/Auth/ConfirmEmail';
import MyProfile from './pages/MyProfile';
import MyCompany from './pages/MyCompany';
import NewOrderType from './pages/NewOrderType';
import OrderTypeDetail from './pages/OrderTypeDetail';
import MyTeam from './pages/MyTeam';
import TeammateDetail from './pages/MyTeam/Detail';
import CreateAccount from './pages/CreateAccount';
import CreateAccountSuccess from './pages/CreateAccount/Success';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import IntakeForm from './pages/IntakeForm';
import ClientList from './pages/ClientList';
import ClientDetail from './pages/ClientDetail';
import ClientOrderDetail from './pages/ClientOrderDetail';
import WorkQueue from './pages/WorkQueue';
import Reminders from './pages/Reminders';
import NewReminder from './pages/NewReminder';
import ReminderDetail from './pages/ReminderDetail';
import UnsubscribeReminders from './pages/UnsubscribeReminders';
import MyReports from './pages/MyReports';
import MobileLock from './pages/MobileLock';
import getPermission from './permissions';

let calledMe = 0;
const Views = () => {
  useMedia('(max-width: 480px)');
  // TODO: timezone
  // In the future, we will set to end user timezone when we saved it
  // moment from moment-timezone
  // moment.tz.setDefault(USER_TIMEZONE);
  const dispatch = useDispatch();
  const [callMeLoading, setCallMeLoading] = useState(false);
  const isLoggedIn = !!useSelector(state =>
    get(state, 'auth.login.access_token', null)
  );
  const currentUser = useSelector(
    state => get(state, 'auth.currentUser.data') || {}
  );
  const permission = getPermission(currentUser.role);
  useRefreshToken();

  useEffect(() => {
    if (isLoggedIn) {
      calledMe = 0;
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);
  const renderAuthenticatedRoutes = () => {
    if (!isLoggedIn) {
      // if not logged in, then redirect to login
      return (
        <Redirect
          to={{
            pathname: ROUTES.LOGIN(),
            state: {
              // Set previous path and search query to support redirect into previous url after did login
              search: window.location.search,
              prevLocation: window.location.pathname,
            },
          }}
        />
      );
    }
    return (
      <>
        <Switch>
          <Route
            path={ROUTES.MY_PROFILE()}
            render={props => (
              <MainLayout component={MyProfile} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.MY_REPORTS()}
            render={props => (
              <MainLayout component={MyReports} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.REMINDER_DETAIL()}
            render={props => (
              <MainLayout component={ReminderDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.NEW_REMINDER()}
            render={props => (
              <MainLayout component={NewReminder} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.REMINDERS()}
            render={props => (
              <MainLayout component={Reminders} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.TEAMMATE_DETAIL()}
            render={props => (
              <MainLayout component={TeammateDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.MY_TEAM()}
            render={props => (
              <MainLayout component={MyTeam} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.COMPANY_ORDER()}
            render={props => (
              <MainLayout component={ClientOrderDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.CLIENT_ORDER_DETAIL()}
            render={props => (
              <MainLayout component={ClientOrderDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.CLIENT_DETAIL()}
            render={props => (
              <MainLayout component={ClientDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.CLIENT_LIST()}
            render={props => (
              <MainLayout component={ClientList} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.MY_COMPANY()}
            render={props => (
              <MainLayout component={MyCompany} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.NEW_ORDER_TYPE()}
            render={props => (
              <MainLayout component={NewOrderType} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.ORDER_TYPE_DETAIL()}
            render={props => (
              <MainLayout component={OrderTypeDetail} {...props} noPadding />
            )}
          />
          <Route
            path={ROUTES.DASHBOARD()}
            render={props => (
              <MainLayout component={Dashboard} {...props} noPadding />
            )}
          />
          {QUEUE_DEPARTMENTS.length > 0 && (
            <>
              {QUEUE_DEPARTMENTS.map(({ upperText, cName, name }) => {
                return (
                  <Route
                    key={cName}
                    path={ROUTES[`${upperText}_WORK_QUEUE`]()}
                    render={props => (
                      <MainLayout
                        component={WorkQueue}
                        {...props}
                        noPadding
                        departmentName={cName}
                        fullDepartmentName={name}
                      />
                    )}
                  />
                );
              })}
            </>
          )}
        </Switch>
      </>
    );
  };
  if (isLoggedIn && calledMe === 0 && !callMeLoading) {
    setCallMeLoading(true);
    dispatch(
      getMe(res => {
        calledMe = 1;
        setCallMeLoading(false);
        if (res) {
          socket.start(res);
        } else {
          socket.stop();
        }
      })
    );
    dispatch(getFieldCategories());
    dispatch(getDepartments());
    dispatch(getDepartmentsList());
    dispatch(getStates());
    dispatch(getSystemCodes());
    dispatch(getSalesDepartmentUsers());
    dispatch(getRoles());
    dispatch(getUnreadNotifications());
    dispatch(getReadNotifications());
    dispatch(getNotificationUnreadCount());
    dispatch(getReportFields());
    dispatch(getClientList());
  }
  if (callMeLoading) {
    return (
      <div className="main_first_loading_container">
        <Spin spinning={callMeLoading} tip={'Loading...'}></Spin>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/components" render={props => <IndexView {...props} />} />
        <Route
          path={ROUTES.MOBILE_LOCK()}
          render={props => (
            <AuthLayout component={MobileLock} {...props} emptyPage />
          )}
        />
        <Route
          path={ROUTES.UNSUBSCRIBE_REMINDERS()}
          render={props => (
            <AuthLayout component={UnsubscribeReminders} {...props} isSSmall />
          )}
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD()}
          render={props => (
            <AuthLayout component={ForgotPassword} {...props} isSmall />
          )}
        />
        <Route
          path={ROUTES.CONFIRM_EMAIL()}
          render={props => (
            <AuthLayout
              component={ConfirmEmail}
              {...props}
              isSmall
              isConfirmEmail
              hasFooter
            />
          )}
        />
        <Route
          path={ROUTES.RESET_PASSWORD()}
          render={props => (
            <AuthLayout component={ResetPassword} {...props} isSmall />
          )}
        />
        <Route
          path={ROUTES.LOGIN()}
          render={props => <AuthLayout component={Login} {...props} isSmall />}
        />
        <Route
          path={ROUTES.CLIENT_REGISTRATION()}
          render={props => (
            <AuthLayout component={Registration} {...props} isRegister />
          )}
        />
        <Route
          path={ROUTES.CREATE_ACCOUNT_SUCCESS()}
          render={props => (
            <PublicLayout
              component={CreateAccountSuccess}
              {...props}
              isWhiteBg
            />
          )}
        />
        <Route
          path={ROUTES.CREATE_ACCOUNT()}
          render={props => (
            <PublicLayout component={CreateAccount} {...props} />
          )}
        />
        <Route
          path={ROUTES.ACCEPT_INVITE()}
          render={props => (
            <PublicLayout component={CreateAccount} {...props} />
          )}
        />
        <Route
          path={ROUTES.HOME()}
          render={props => <PublicLayout component={Home} {...props} />}
        />
        <Route
          path={ROUTES.INTAKE_FORM()}
          render={props => (
            <PublicLayout hideFooter={true} component={IntakeForm} {...props} />
          )}
        />
        <Route
          exact={true}
          path={ROUTES.INDEX()}
          render={() => (
            <Redirect
              to={isLoggedIn ? permission.redirectUrl : ROUTES.LOGIN()}
            />
          )}
        />
        {renderAuthenticatedRoutes()}
        <Redirect from="*" to={ROUTES.LOGIN()} />
      </Switch>
    </BrowserRouter>
  );
};

export default Views;
